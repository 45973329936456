import React from 'react';
import {useSelector} from 'react-redux';

const CouponCodeUI = ({couponCodeLocalState, setCouponCode, _handleValidateCouponCode}) => {
  const bookingDetails = useSelector(state => state.booking);
  let show;
  if (bookingDetails?.selectedPackage?.inst) {
    if (bookingDetails?.selectedPackage?.inst?.allowDiscount === true) {
      show = true;
    } else {
      show = false;
    }
  } else {
    show = true;
  }
  return (
    <>
      {show && (
        <>
          <h6>Do you have a discount code?</h6>
          <div className="row mt-2">
            <div className="col-6 col-md-4">
              <input
                type="text"
                className="form-control"
                placeholder="Please enter discount code"
                onChange={e => {
                  setCouponCode(p => ({
                    ...p,
                    userCode: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="col-6 col-md-4 margin-l-0">
              {couponCodeLocalState?.userCode != '' && (
                <>
                  <button
                    className="btn btnDefault "
                    onClick={_handleValidateCouponCode}
                    disabled={couponCodeLocalState?.loading}
                  >
                    {couponCodeLocalState?.loading ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : (
                      'Verify'
                    )}
                  </button>
                </>
              )}
            </div>
          </div>
          {bookingDetails?.couponCode?.isValid && (
            <div className="text-success mt-2">
              Discount applied = {bookingDetails?.couponCode?.couponDetails?.title}
            </div>
          )}
          {bookingDetails?.couponCode?.isValid !== null && !bookingDetails?.couponCode?.isValid && (
            <div className="text-warning mt-2">Not a valid discount code!</div>
          )}
        </>
      )}
    </>
  );
};
export default CouponCodeUI;
