import React from "react";
const SignIn = React.lazy(() => import("./beforeLogin/SignIn"));
// const SignUp = React.lazy(() => import("./beforeLogin/SignUp"));
const ForgotPassword = React.lazy(() => import("./beforeLogin/ForgotPassword"));
const PublicBooking = React.lazy(() => import("./afterLogin/PublicBooking"));
const ClientDashboard = React.lazy(() =>
  import("./afterLogin/ClientDashboard")
);
const ConfirmBookingPage = React.lazy(() =>
  import("./afterLogin/ConfirmBookingPage")
);
const Booking = React.lazy(() => import("./afterLogin/Booking"));
const RescheduleAppointment = React.lazy(() =>
  import("./afterLogin/RescheduleAppointment")
);
const FamilyMembers = React.lazy(() => import("./afterLogin/FamilyMembers"));
const PaymentPage = React.lazy(() => import("./afterLogin/PaymentPage"));
const Favorites = React.lazy(() => import("./afterLogin/Favorites"));
const Explore = React.lazy(() => import("./afterLogin/Explore"));
const Details = React.lazy(() => import("./afterLogin/Details"));
const Waiver = React.lazy(() => import("./common/Waiver"));
const PostBookingConfirmation = React.lazy(() =>
  import("./afterLogin/PostBookingConfirmation")
);
const InstallmentsCardUpdate = React.lazy(() =>
  import("./Others/InstallmentsCardUpdate/CardUpdate")
);
const CheckIn = React.lazy(() => import("./Others/CheckIn"));
const PostSignUp = React.lazy(() => import("./beforeLogin/PostSignUp"));
import AthleteProfile from "./common/AthleteProfile";
// import ChatPage from "./afterLogin/ChatPage";
// import CoachDashboard from "./afterLogin/CoachDashboard";

export {
  ClientDashboard,
  SignIn,
  ForgotPassword,
  // SignUp,
  Booking,
  RescheduleAppointment,
  PublicBooking,
  ConfirmBookingPage,
  FamilyMembers,
  PaymentPage,
  Favorites,
  Explore,
  Details,
  PostBookingConfirmation,
  Waiver,
  CheckIn,
  PostSignUp,
  AthleteProfile,
  InstallmentsCardUpdate,
};
