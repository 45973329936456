import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const authDetails = useSelector((state) => state.auth.authDetails);
  const bookingDetails = useSelector((state) => state.booking);
  const redirectPublicUser = useSelector((state) => state.publicUser);

  const publicRedirectUrl = () => {
    if (redirectPublicUser.classId && redirectPublicUser.companyId) {
      return "/class-booking";
    } else if (
      bookingDetails.service &&
      bookingDetails.trainer &&
      bookingDetails.session_type === "PUBLIC_APPOINTMENT_BOOKING"
    ) {
      return "/booking";
    } else {
      const from = location.state?.from || '/dashboard';
      return from;
      //return "/dashboard";
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        // If user is authenticated and route is restricted (like login page),
        // redirect them to a public landing route.
        authDetails && restricted && path !== "auto-login" ? (
          <Redirect
            to={{
              pathname: publicRedirectUrl(),
              state: { from: location.pathname }
            }}
          />
        ) : (
          // Otherwise, render the requested component
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
