import {toast} from 'react-toastify';
import PackUI from './PackUI';
import {useDispatch, useSelector} from 'react-redux';
import {addSelectedPackage} from '../../../redux/actions';
import moment from 'moment';
import {installmentsToDisplay} from '../utils';
import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';

const CampPacks = ({selectedPackage, campPacksList, isInst, setIsInstallmentPlanSelected}) => {
  const bookingDetails = useSelector(state => state.booking);
  const {repeat_id, allow_book_individually, one_time_fee, start_branch_time} =
    bookingDetails?.classDetails || {};
  const dispatch = useDispatch();

  const displayInstallments = installmentsToDisplay(selectedPackage?.inst);
  console.log('displayInstallments',displayInstallments)
  const [installmentsmodal, setInstallmentsmodal] = useState(false);
  let instPackages = campPacksList;
  console.log('instPackages', instPackages);
  instPackages = campPacksList
    .map((inst, originalIndex) => ({...inst, _originalIndex: originalIndex}))
    .sort((a, b) => {
      const paymentsA = a.inst?.installments.length;
      const paymentsB = b.inst?.installments.length;

      if (paymentsA !== paymentsB) {
        return paymentsA - paymentsB;
      }

      // If they have the same number of payments, sort by name alphabetically
      return a.inst?.name.localeCompare(b.inst?.name);
    });

  console.log('bookingDetails', bookingDetails);
  return (
    <>
      {repeat_id && allow_book_individually && !isInst && (
        <>
          <p className="text-dark bg-light text-left pl-3 py-2 my-3 text-dark fw-normal">
            Please select if you would like to book all the days of the event or just the present
            day?
          </p>

          {/* Ensuring equal height columns */}
          <div className="row d-flex align-items-stretch row-cols-1 row-cols-md-4 mb-3 text-center">
            {campPacksList.map(pack => (
              <div className="col-md-4 d-flex align-items-stretch mb-3" key={pack.label}>
                <PackUI
                  {...pack}
                  _handleOnClick={() => dispatch(addSelectedPackage(pack))}
                  isSelected={pack.campBookType === selectedPackage?.campBookType}
                  isShowBookingDate={pack.campBookType === 'single'}
                  bookingDate={moment(start_branch_time).format('MM/DD/YYYY')} // MM/DD/YYYY format
                  coupon={bookingDetails?.couponCode?.couponDetails}
                />
              </div>
            ))}
          </div>

          {one_time_fee && (
            <div className="pl-3">
              <input
                className="form-check-input"
                type="checkbox"
                id={`check1`}
                checked={selectedPackage?.isOneTimeMembershipFeeEnabled}
                onChange={() => {
                  if (!selectedPackage?.price) {
                    toast.error('You need to select at-least one package!');
                    dispatch(
                      addSelectedPackage({
                        ...selectedPackage,
                        isOneTimeMembershipFeeEnabled: false,
                      })
                    );
                    return;
                  }
                  dispatch(
                    addSelectedPackage({
                      ...selectedPackage,
                      isOneTimeMembershipFeeEnabled:
                        !selectedPackage?.isOneTimeMembershipFeeEnabled,
                    })
                  );
                }}
              />
              <p>
                There is a member fee of $ {one_time_fee}, please check this box if you have not
                paid the membership fee yet
              </p>
            </div>
          )}
        </>
      )}

      {!repeat_id && !isInst && (
        <>
          <p className="text-dark bg-light text-left pl-3 py-2 my-3 text-dark fw-normal">
            Packages
          </p>
          <div className="row d-flex align-items-stretch row-cols-1 row-cols-md-4 mb-3 text-center">
            <div className="col-md-4 d-flex align-items-stretch mb-3">
              <PackUI {...selectedPackage} coupon={bookingDetails?.couponCode?.couponDetails} />
            </div>
          </div>
        </>
      )}

      {repeat_id && !allow_book_individually && !isInst && (
        <>
          <p className="text-dark bg-light text-left pl-3 py-2 my-3 text-dark fw-normal">
            All the days of the event will be booked
          </p>
          <div className="row d-flex align-items-stretch row-cols-1 row-cols-md-4 mb-3 text-center">
            <div className="col-md-4 d-flex align-items-stretch mb-3">
              <PackUI {...selectedPackage} coupon={bookingDetails?.couponCode?.couponDetails} />
            </div>
          </div>
        </>
      )}

      {isInst && (
        <>
          <p className="text-dark bg-light text-left pl-3 py-2 my-3 text-dark fw-normal">
            Please select the package you would like to book
          </p>

          <div className="row d-flex align-items-stretch row-cols-1 row-cols-md-4 mb-3 text-center">
            {instPackages.map(pack => {
              console.log('pack', pack)
              return (
                <div className="col-md-4 d-flex align-items-stretch mb-3" key={pack.label}>
                  <PackUI
                    {...pack}
                    isInst={isInst}
                    _handleOnClick={() => {
                      dispatch(addSelectedPackage(pack));
                      setInstallmentsmodal(true);
                      setIsInstallmentPlanSelected(true);
                    }}
                    isSelected={pack._originalIndex === selectedPackage.id}
                    isShowBookingDate={false}
                    coupon={bookingDetails?.couponCode?.couponDetails}
                    fmCount={bookingDetails?.selectedFamilyMembers?.length ?? 1}
                  />
                </div>
              );
            })}
          </div>
          <div className="row d-flex align-items-stretch">
            {selectedPackage?.installmentType === 'installment' &&
              displayInstallments?.length > 0 &&
              !installmentsmodal && (
                <div
                  className="ml-2 mr-2"
                  style={{
                    width: '40%',
                    boxSizing: 'border-box',
                    padding: '15px',
                    borderWidth: '2px',
                    borderStyle: 'dashed',
                    borderColor: 'red',
                    borderRadius: '10px',
                    backgroundColor: '#f9f9f9',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    margin: '0 auto',
                  }}
                >
                  <h3 className="text-center" style={{color: '#333', marginBottom: '15px'}}>
                    {selectedPackage?.inst?.name}
                  </h3>
                  <br />
                  <div
                    className="font-weight-bold font-size-15"
                    style={{
                      marginBottom: '15px',
                      color: '#555',
                      fontSize: '16px',
                    }}
                  >
                    Following installments will be charged on the <br /> following dates:
                  </div>
                  {displayInstallments?.map((installment, index) => {
                    console.log('installment', installment)
                    const instPrice = installment.price?.toFixed(2);
                    let discountedPrice = null;
                    if(installment?.allowDiscount === true){
                    if (bookingDetails?.couponCode?.couponDetails?.percent) {
                      discountedPrice = (
                        installment.price -
                        (installment.price * bookingDetails?.couponCode?.couponDetails?.percent) /
                          100
                      )?.toFixed(2);
                    }}
                    return (
                      <div
                        key={index}
                        className="font-size-14 lh-lg"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '8px 0',
                          borderBottom: '1px solid #eee',
                          color: '#333',
                        }}
                      >
                        <span>
                          {installment.dt == 'At the time of booking' || installment.dt == null
                            ? installment?.dt
                            : moment(installment.dt).format('MM/DD/YYYY')}
                        </span>
                        {discountedPrice ? (
                          <span>
                            <span
                              className="text-decoration-line-through text-danger"
                              style={{marginRight: '8px'}}
                            >
                              {/* ${installment.price} X {bookingDetails?.selectedFamilyMembers?.length} =  */}
                              ${bookingDetails?.selectedFamilyMembers?.length > 0 ? (instPrice * bookingDetails?.selectedFamilyMembers?.length)?.toFixed(2) : instPrice}
                            </span>
                            <span style={{color: 'green'}}>
                              {/* ${discountedPrice} X {bookingDetails?.selectedFamilyMembers?.length} =  */}
                              ${bookingDetails?.selectedFamilyMembers?.length > 0 ? (discountedPrice * bookingDetails?.selectedFamilyMembers?.length)?.toFixed(2) : discountedPrice}</span>
                          </span>
                        ) : (
                          <span>
                            {/* ${installment.price} X {bookingDetails?.selectedFamilyMembers?.length} =  */}
                            ${bookingDetails?.selectedFamilyMembers?.length > 0 ? (instPrice * bookingDetails?.selectedFamilyMembers?.length)?.toFixed(2) : instPrice}</span>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
          </div>
          {selectedPackage?.installmentType === 'installment' &&
            displayInstallments?.length > 0 && (
              <Modal show={installmentsmodal} onHide={() => setInstallmentsmodal(false)}>
                <Modal.Header>
                  <h4 className="modal-title">{selectedPackage?.inst?.name}</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setInstallmentsmodal(false);
                      dispatch(addSelectedPackage(null));
                    }}
                  ></button>
                </Modal.Header>
                <Modal.Body>
                  <div className="mx-auto" style={{width: '100%'}}>
                    <div className="font-weight-bold font-size-16" style={{marginBottom: '16px'}}>
                      The payment schedule for this package is:
                    </div>
                    {displayInstallments?.map((installment, index) => {
                      let discountedPrice = null;
                      const instPrice = installment.price?.toFixed(2);
                      console.log('installment33',installment)
                      if(installment?.allowDiscount === true){
                        if (bookingDetails?.couponCode?.couponDetails?.percent) {
                          discountedPrice = (
                            installment.price -
                            (installment.price * bookingDetails?.couponCode?.couponDetails?.percent) /
                              100
                          )?.toFixed(2);
                        }
                      }
                      return (
                        <div
                          key={index}
                          className="font-size-14"
                          style={{
                            marginBottom: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '8px 0',
                            borderBottom: '1px solid #e0e0e0',
                            textAlign: 'left',
                          }}
                        >
                          <span>
                            {installment.dt == 'At the time of booking' || installment.dt == null
                              ? installment?.dt
                              : moment(installment.dt).format('MM/DD/YYYY')}
                          </span>
                          {discountedPrice ? (
                            <span>
                              <span
                                className="text-decoration-line-through text-danger"
                                style={{marginRight: '8px'}}
                              >
                                {/* ${installment.price} X {bookingDetails?.selectedFamilyMembers?.length} =  */}
                                ${bookingDetails?.selectedFamilyMembers?.length > 0 ? (instPrice * bookingDetails?.selectedFamilyMembers?.length)?.toFixed(2) : instPrice}
                              </span>
                              <span style={{color: 'green'}}>
                                {/* ${discountedPrice} X {bookingDetails?.selectedFamilyMembers?.length} =  */}
                                ${bookingDetails?.selectedFamilyMembers?.length > 0 ? (discountedPrice * bookingDetails?.selectedFamilyMembers?.length)?.toFixed(2) : discountedPrice}</span>
                            </span>
                          ) : (
                            <span>
                              {/* ${installment.price} X {bookingDetails?.selectedFamilyMembers?.length} =  */}
                              ${bookingDetails?.selectedFamilyMembers?.length > 0 ? (instPrice * bookingDetails?.selectedFamilyMembers?.length)?.toFixed(2) : instPrice}</span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <br />
                  <div
                    className="text-center mr-auto ml-auto"
                    style={{display: 'flex', justifyContent: 'center', gap: '15px'}}
                  >
                    <button
                      className="btn btn-outline-danger px-md-5"
                      onClick={() => {
                        setInstallmentsmodal(false);
                        setIsInstallmentPlanSelected(false);
                        dispatch(addSelectedPackage({}));
                      }}
                    >
                      Back
                    </button>
                    {!selectedPackage?.isSelectBtn && (
                      <button
                        type="button"
                        className="btn btn-primary px-5 font-size-15"
                        onClick={() => {
                          dispatch(
                            addSelectedPackage({
                              ...selectedPackage,
                              isSelectBtn: true,
                            })
                          );
                          setInstallmentsmodal(false);
                        }}
                      >
                        {selectedPackage?.isSelectBtn ? 'Installment Plan Selected' : 'Select'}
                      </button>
                    )}
                  </div>
                </Modal.Body>
              </Modal>
            )}
        </>
      )}
    </>
  );
};

export default CampPacks;
