import React from 'react';
import {Route, Redirect, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

const PrivateRoute = ({component: Component, ...rest}) => {
  const authDetails = useSelector(state => state?.auth?.authDetails);
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={props =>
        authDetails ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: location.pathname},
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
