import './PackUI.scss';
const PackUI = props => {
  const {label, price, quantity, isSelected, isShowBookingDate, bookingDate, coupon, fmCount} =
    props || {};
  console.log('props', props);
  const packLabel = `${label} ${isShowBookingDate ? `for ${bookingDate}` : ''}`;
  let discountedPrice;
  let decimalPrice = parseFloat(price);
  decimalPrice = Number.isInteger(decimalPrice) ? decimalPrice : decimalPrice?.toFixed(2);
  let show;
  if (coupon) {
    if (!props?.inst || props.inst?.allowDiscount == true) {
      show = true;
      discountedPrice = (price - (price * coupon?.percent) / 100)?.toFixed(2);
    }
  } else {
    show = false;
  }
  return (
    <div className="col">
      <div
        className={`card mb-4 rounded-3 shadow-sm ${isSelected ? 'selected' : ''}`}
        role="button"
        onClick={props._handleOnClick}
      >
        <div className="card-header py-3 bg-dark">
          <h4 className="my-0 fw-normal text-white fs-5 pack-card">{packLabel}</h4>
        </div>
        <div className="card-body">
          <h2
            className={
              'pricing-card-title mb-0' +
              (show &&
                ' text-decoration-line-through text-danger')
            }
          >
            {/* {props.isInst ? "$" + decimalPrice + "X" + fmCount : "$" + decimalPrice} */}$
            {decimalPrice}
          </h2>
          <p className="font-size-12">(includes all fees)</p>
          {show && (
            <h2 className="pricing-card-title mb-0">
              {/* {props.isInst ? "$" + discountedPrice + "X" + fmCount : "$" + discountedPrice} */}
              ${discountedPrice}
            </h2>
          )}
          {quantity && (
            <ul className="list-unstyled mt-3 mb-4">
              <li className="p-1 border-bottom border-top">
                {`${quantity} Session${quantity > 1 ? 's' : ''}`}
              </li>
            </ul>
          )}
        </div>
        {props.isInst && (
          <div className="card-footer" style={{backgroundColor: '#2596be'}}>
            <div className="text-white fw-normal fs-5 ">
              {props.inst?.isInstallment ? 'Installment Payments' : 'Single Payment'}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PackUI;
