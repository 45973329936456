import React, {useState, useEffect} from "react";
import {Modal} from "react-bootstrap";
import Moment from "moment";
import {useHistory} from "react-router-dom";
import {
  addSelectedPackage,
  clearCompanyData,
  redirectPublicUser,
  addClassBookingDetails,
  authCompanyData,
} from "../../redux/actions/index";
import {ToastNotification} from "../index";
import {IMAGE_URL, BASE_URL_WEB} from "../../utility/constants";
import {useSelector, useDispatch} from "react-redux";
import {calendar} from "../../assets/imageDir";
import {
  AxiosHelperPortal,
  openInNewTab,
  calcClassRemainingSpots,
  showToast,
} from "../../utility/helperFunctions";
import {BOOKING_TYPES} from "../../common/config";

const EventsClassModal = ({show, onHide, details}) => {
  const history = useHistory();
  const authDetails = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // State variables
  const [memberService, setMemberService] = useState({});
  const [groupClassDetail, setGroupClassDetail] = useState({});
  const [remainingCredits, setRemainingCredits] = useState(0);
  const [membership_pack, setMembershipPack] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cancelClassLoading, setCancelClassLoading] = useState(false);
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [isClassFull, setIsClassFull] = useState(false);

  // NEW: controls whether the "confirm cancellation" modal is shown
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);

  useEffect(() => {
    let temp = {};

    if (authDetails?.companyData?.member_service?.length > 0) {
      temp = authDetails?.companyData?.member_service.filter(
          (e) => e.serviceId === details?.serviceId
      );
      const groupClassDetails =
          authDetails?.companyData?.services?.group_class?.filter(
              (item) => item?.uuid === details?.serviceId
          );

      setGroupClassDetail(groupClassDetails);
      setMemberService(temp[0]);

      console.group("events class modal");
      console.log(details);
      console.log(groupClassDetails[0]?.campDaysBookTogether);
      console.groupEnd("events class modal");

      if (calcClassRemainingSpots(details) === "Class Full") {
        setIsClassFull(true);
      } else {
        setIsClassFull(false);
      }
    }
  }, [details, authDetails?.companyData]);

  useEffect(() => {
    const member_services = authDetails?.companyData?.member_service;
    let new_member_service = null;
    if (member_services?.length != null && member_services.length > 0) {
      new_member_service = member_services.filter(
          (e) => e.typename === "membership_plan_subscribers"
      );
    }
    if (new_member_service !== null && details?.serviceId != null) {
      getRemainingSpotsAjax();
    } else {
      getRemainingSpots();
    }
  }, [details, authDetails?.companyData]);

  // Fetch remaining class spots from API if membership plan
  const getRemainingSpotsAjax = () => {
    let url = BASE_URL_WEB + "client-class-member-service?";
    url += "&serviceId=";
    url += details?.serviceId;
    console.log("member-branch-trainer url", url);
    setLoading(true);
    AxiosHelperPortal(url, "get")
        .then((response) => {
          console.log("fetch remaining data", response);
          const data = response.data;
          const membership_pack = data?.membership_pack;
          const unlimited = data?.unlimited;
          if (data.length === 0) {
            setRemainingCredits(0);
            setMembershipPack(membership_pack);
          } else {
            setRemainingCredits(data.ptRemaining);
            setMembershipPack(membership_pack);
            setIsUnlimited(unlimited);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          setLoading(false);
        });
  };

  // Fetch remaining spots for multi-class packs or single service credits
  const getRemainingSpots = () => {
    let remaining = 0;
    const member_services = authDetails?.companyData?.member_service;
    const multi_class_pack =
        authDetails?.companyData?.services?.multi_class_pack;

    if (member_services?.length != null && member_services.length > 0) {
      let new_member_service = member_services.filter(
          (e) =>
              (e.typename === "member_multi_class_pack" &&
                  e?.ptRemaining != null &&
                  e.ptRemaining > 0 &&
                  multi_class_pack.length != null &&
                  multi_class_pack.length > 0 &&
                  multi_class_pack.find(
                      (m) =>
                          (m.uuid === e.multiClassPackId && m?.isAllClassPack === true) ||
                          (m?.selectedClasses?.length != null &&
                              m?.selectedClasses?.length > 0 &&
                              m?.selectedClasses.includes(details?.serviceId))
                  )) ||
              (e.typename === "member_service" &&
                  e?.ptRemaining != null &&
                  e.ptRemaining > 0 &&
                  e.serviceId === details?.serviceId)
      );

      if (new_member_service?.length != null && new_member_service?.length > 0) {
        remaining = new_member_service.reduce((total, val) => {
          if (val?.ptRemaining != null) {
            return total + val.ptRemaining;
          }
          return total;
        }, 0);
      }
    }
    setRemainingCredits(remaining);
  };

  // ================================================
  // 1) Extracted cancellation logic to a new function
  // ================================================
  const cancelClass = async () => {
    setCancelClassLoading(true);

    const memberId = authDetails?.companyData?.member?.id;
    const payload = {
      cancelAllAppointments: null,
      cancelType: "session_credit",
      cancelledReason: "Cancelled by client",
      groupClassId: details?.uuid,
      partialRefundAmount: null,
      paymentMethod: "pay_now_cash",
      refundType: null,
      uuid: details?.bookingId,
      memberId: memberId,
      orderId: details?.orderId,
      isGroupClass: "yes",
      from: "client_portal",
      fmIds: ["_parent"],
      isFromClient: true,
    };
    console.log("payload", payload);
    const url = `${BASE_URL_WEB}appointment-cancel?`;
    console.log("url", url, payload);

    try {
      const response = await AxiosHelperPortal(url, "post", payload);
      console.log("response", response);

      dispatch(authCompanyData());
      history.push({
        pathname: "/class-booking",
      });
      setCancelClassLoading(false);
      showToast.success("Your class is cancelled successfully.");
    } catch (error) {
      console.log("err", error);
      setCancelClassLoading(false);
      showToast.error(error.response?.data?.message);
    }
  };

  // ================================================
  // 2) Modified onBook to show confirmation modal
  // ================================================
  const onBook = () => {
    // If user is about to cancel
    if (
        details?.isBooked &&
        details.typename !== BOOKING_TYPES.APPOINTMENT &&
        details?.familyMembers?.length === 0
    ) {
      // Show "Are you sure you want to cancel?" modal
      setShowCancelConfirmModal(true);
    } else {
      // Otherwise, continue booking flow
      dispatch(addSelectedPackage(null));
      details.isClassFull = isClassFull;
      dispatch(addClassBookingDetails(details));
      history.push({
        pathname: "/confirm-booking",
      });
    }
  };

  // If the user is not logged in, redirect them to login
  const onBookPublicMoveToLoginPage = () => {
    dispatch(addSelectedPackage(null));
    dispatch(clearCompanyData());
    dispatch(
        redirectPublicUser({
          companyId: authDetails?.companyData?.id,
          companyName: authDetails?.companyData?.name,
          classId: details?.uuid,
          branchDetails: authDetails?.companyData?.branches?.items,
          imageUrl: authDetails?.companyData?.imageUrl,
          showKidsAfterSignup: authDetails?.companyData?.showKidsAfterSignup,
          eventDate: details?.start_branch_time,
          haveKids: details?.haveKids,
        })
    );
    history.push("/login");
  };

  // Decide the text for the main button
  const showContinueBtnText = () => {
    let txt = "Continue Booking";

    const isBookedIsCampNoBooking = details?.isBooked && details?.isCamp;
    if (isBookedIsCampNoBooking) {
      txt = "View Details";
    } else if (
        details?.isBooked &&
        details.typename !== BOOKING_TYPES.APPOINTMENT &&
        details?.familyMembers?.length === 0
    ) {
      txt = "Cancel Registration";
    } else if (
        details?.isBooked &&
        details.typename !== BOOKING_TYPES.APPOINTMENT
    ) {
      txt = "Update Registration";
    }
    return txt;
  };

  return (
      <>
        {/* Existing modal for class details */}
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="class-info-modal"
        >
          <div className="modal-content-inner">
            {!isClassFull ? (
                <>
                  {details?.status !== "cancelled" && (
                      <div className="btn-div text-center mt-3">
                        {authDetails.authDetails ? (
                            <button
                                className="btn _btnRound"
                                onClick={onBook}
                            >
                              {showContinueBtnText()}
                            </button>
                        ) : (
                            <button
                                className={"btn _btnRound"}
                                onClick={onBookPublicMoveToLoginPage}
                                style={{backgroundColor: "#c82638", color: "white"}}
                            >
                              Continue Booking
                            </button>
                        )}
                      </div>
                  )}
                </>
            ) : (
                <>
                  {details.haveKids && details.haveKids === true ? (
                      <>
                        <button className="btn _btnRound" onClick={onBook}>
                          Update Registration
                        </button>
                      </>
                  ) : (
                      <>
                        <button className="btn _btnRound" disabled={true}>
                          {details.isCamp ? 'Camp Full' : 'Class Full'}
                        </button>
                      </>
                  )}
                </>
            )}
            <div className="modal-header">
              <h5 className="modal-title">
                {details?.isCamp ? "Camp Details" : "Class Details"}
              </h5>
              <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={onHide}
              ></button>
            </div>
            <div className="modal-body">
              {isClassFull && details.haveKids && details.haveKids === true && (
                  <div
                      style={{
                        backgroundColor: '#f8d7da',
                        padding: '5px',
                        borderRadius: '4px',
                        fontFamily: 'Arial, sans-serif'
                      }}
                  >
                    <h6 className="text-danger">
                      {details.isCamp ? 'Camp Full' : 'Class Full'}
                    </h6>
                  </div>
              )}
              <div className="classInfoBlock">
                <figure className="classInfo-img">
                  {details?.imageUrl && (
                      <img
                          src={IMAGE_URL + details?.imageUrl}
                          alt=""
                          className="img-fluid"
                      />
                  )}
                </figure>

                <div>
                  <p
                      className="service text-dark _name _title-3"
                      style={{fontWeight: "500"}}
                  >
                  <span className="icon-holder-txt">
                    {details?.virtualSession && <i className="fas fa-video"></i>}
                    {details?.isCamp && details?.commonT
                        ? details?.commonT
                        : details?.serviceName}
                    , {details?.branchName}
                  </span>
                  </p>

                  <pre className="desc-normal">
                  {details?.isCamp && details?.commonDes
                      ? details?.commonDes
                      : details?.description}
                </pre>

                  {details?.zoomLink && details?.isBooked && (
                      <div className="zoomLink">
                        <p className="_name _title-3 mr-2">Zoom Link:</p>
                        <span
                            className="iconRound"
                            onClick={() => openInNewTab(details?.zoomLink)}
                            onKeyPress={null}
                            role="link"
                            tabIndex={-1}
                        >
                      <i className="bx bx-video"></i>
                    </span>
                      </div>
                  )}
                </div>

                {!details?.onlineBooking &&
                    typeof details?.access !== "undefined" &&
                    details?.access === "private" && (
                        <div className="text-center m-3">
                    <span className="text-danger font-weight-bold">
                      Sorry ! This class is not available for online booking.
                    </span>
                        </div>
                    )}
                {details?.status === "cancelled" && (
                    <div className="text-center m-3">
                  <span className="text-danger font-weight-bold">
                    Sorry! This class is cancelled.
                  </span>
                    </div>
                )}

                <div className="date-time-div">
                  <div className="date-div">
                  <span className="_icon">
                    <img src={calendar} alt="" />
                  </span>
                    {Moment(details?.start_branch_time).format(
                        "dddd, MMMM Do YYYY"
                    )}
                  </div>
                  <div className="time-div">
                  <span className="_icon">
                    <i className="far fa-clock"></i>
                  </span>
                    {Moment(details?.start_branch_time).format("hh:mm A")}
                  </div>
                </div>
              </div>

              <div className="cinfo-div-outter mb-5 mt-3">
                <div className="cinfo-div mb-2">
                <span className="cinfo-title">
                  <i className="bx bx-user"></i> Coaches
                </span>
                  <span className="cinfo">
                  {details?.selectedTrainers?.map((trainer, index) => (
                      <span key={trainer?.id}>
                      {trainer?.trainerName}
                        {details?.selectedTrainers?.length - 1 !== index && ", "}
                    </span>
                  ))}
                </span>
                </div>

                {memberService !== null &&
                    membership_pack == null &&
                    authDetails?.authDetails &&
                    !groupClassDetail[0]?.campDaysBookTogether &&
                    !groupClassDetail[0]?.isCamp && (
                        <div className="cinfo-div">
                    <span className="cinfo-title">
                      <i className="bx bx-money"></i>Credits Remaining for{" "}
                      {details?.serviceName}:{" "}
                    </span>
                          <span className="cinfo">
                      {loading ? "Loading....." : remainingCredits}
                    </span>
                        </div>
                    )}
                {membership_pack != null && (
                    <div className="cinfo-div">
                  <span className="cinfo-title">
                    <i className="bx bx-money"></i>Credits Remaining for{" "}
                    {details?.serviceName}:{" "}
                  </span>
                      <span className="cinfo">
                    {loading === true && "Loading....."}
                        {isUnlimited === true && loading !== true &&
                            "Unlimited membership pack active"}
                        {isUnlimited !== true && loading !== true && remainingCredits}
                  </span>
                    </div>
                )}
              </div>
            </div>
          </div>

          {cancelClassLoading && (
              <div className="overlay">
                <div className="spinner"></div>
              </div>
          )}
        </Modal>

        {/* NEW: "Are you sure you want to cancel?" Modal */}
        <Modal
            show={showCancelConfirmModal}
            onHide={() => setShowCancelConfirmModal(false)}
            centered
        >
          <div className="modal-header">
            <h5 className="modal-title">Cancel Class</h5>
            <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setShowCancelConfirmModal(false)}
            ></button>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to cancel your class?</p>
          </div>
          <div className="modal-footer">
            <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowCancelConfirmModal(false)}
            >
              No
            </button>
            <button
                type="button"
                className="btn btn-danger"
                onClick={cancelClass}
            >
              Yes, Cancel
            </button>
          </div>
        </Modal>

        <ToastNotification />
      </>
  );
};

export default EventsClassModal;
